<template>
<div>
    <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
    <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" v-on:decline-transaction="declineTransactions"/>
    <v-container class="container--fluid grid-list-md text-center">
        <h1>Declined Transactions</h1>
    </v-container>

    <v-card elevation="2" outlined shaped tile padding="20px">
        <v-row class="ma-1">
            <v-col cols="12" md="5">
                <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-from">
                    <template v-slot:activator="{ on }">
                        <v-text-field label="From" prepend-icon="mdi-calendar" readonly :value="fromDateDisp" v-on="on" data-cy="date-menu-from"></v-text-field>
                    </template>
                    <v-date-picker locale="en-in" v-model="fromDateVal" no-title @input="fromDateMenu = false" :min="minDate" :max="fromMax" data-cy="date-menu-from"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="5">
                <v-menu ref="menu" v-model="toDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-to">
                    <template v-slot:activator="{ on }">
                        <v-text-field label="To" prepend-icon="mdi-calendar" readonly :value="toDateDisp" v-on="on" data-cy="date-menu-to"></v-text-field>
                    </template>
                    <v-date-picker locale="en-in" v-model="toDateVal" no-title @input="toDateMenu = false" :max="maxDate" :min="toMin" data-cy="date-menu-to">
                        <v-btn text color="primary" @click="clearToDate()" data-cy="button-clear">Clear
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>

        <v-row class="ma-1">
            <v-col class="d-flex" cols="12" md="2">
                <v-autocomplete
                    clearable chips
                    :items="merchantCodes" 
                    label="Merchant ID" 
                    v-model="filteredTransaction.merchantCode" 
                    data-cy="module-filtered-transaction"
                    return-object
                    @change="searchMerchantCode">
                </v-autocomplete>
            </v-col>
            <v-col class="d-flex" cols="12" md="4">
                <v-text-field class="px-4" label="Payconnect Reference" single-line @change="searchPayConnectRef" data-cy="module-payconnect-reference"></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" md="4">
                <v-text-field class="px-4" label="Merchant Reference" single-line @change="searchMerchantRef" data-cy="module-merchant-reference"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-card-actions elevation="2" class="container--fluid grid-list-md text-center">
                    <v-btn dark medium color=success @click="queryData(true)" data-cy="button-search">
                        <v-icon dark left> mdi-magnify</v-icon>Search
                    </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
    </v-card>

    <!-- <div class="text-uppercase text-bold">id selected: {{selected}}</div> -->
    <!-- <div class="col-md-6 text-right">
        <strong>Total Amount:</strong> <span class="price-content">{{totalAmount}}</span>
    </div> -->

    <template>
        <v-card>
            <v-data-table :hide-default-header="true" :headers="merchantHeaders" :items="displayMerchantTransactions" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalMerchantTransactions" :footer-props="{'items-per-page-options': [10]}">
                <template v-slot:header="{ props: { headers } }">
                        <thead>
                            <tr :colspan="headers.length">
                                <th v-for="header in merchantHeaders" :key="header.text">
                                    <span class="d-flex justify-center">{{header.text}}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr :colspan="merchantHeaders.length" v-for="item in items" :key="item.id">
                                <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.deviceId }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.siteId }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.merchantCode }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.payconnectReference }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.amount }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.merchantReference }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.transactionDate }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.transactionReferenceNo }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.paymentStatus }} </span></td>
                                <td>
                                    <span class="d-flex justify-center">
                                        <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewTransaction(item)">
                                            <v-icon dark medium>mdi-magnify-plus</v-icon>
                                        </v-btn>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                    <template v-slot:no-results>
                        <v-alert :value="true" color="error" icon="warning">
                            Found no results.
                        </v-alert>
                    </template>
            </v-data-table>
        </v-card>
    </template>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import API from '@/api/API'
import Loading from '@/views/components/Loading.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import moment from 'moment';

export default {
    name: 'Unmatched',
    components: {
        Loading,
        DetailsForm
    },
    computed: {
        ...mapGetters([
            'attributes',
            'authorities'
        ]),
        fromDateDisp() {
            this.toMin = moment().format(this.fromDateVal)
            return this.fromDateVal;
        },
        toDateDisp() {
            this.fromMax = moment().format(this.toDateVal)
            return this.toDateVal;
        },
    },
    data() {
        return {
            merchantHeaders: [
                {
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'Device ID',
                    value: 'deviceId'
                },
                {
                    text: 'Site ID',
                    value: 'siteId'
                },
                {
                    text: 'Merchant',
                    value: 'merchantCode'
                },
                {
                    text: 'Reference Number',
                    value: 'payconnectReference'
                },
                {
                    text: 'Amount',
                    value: 'amount'
                },
                {
                    text: 'Merchant Ref. Num.',
                    value: 'merchantReference'
                },
                {
                    text: 'Date',
                    value: 'transactionDate'
                },
                {
                    text: 'Transaction Ref. Num.',
                    value: 'transactionReferenceNo'
                },
                {
                    text: 'Status',
                    value: 'paymentStatus'
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                },
            ],
            filteredTransaction: {},
            listOfMerchantCodes: [],
            merchantCodes: this.listOfMerchantCodes ?? [],
            loading: true,
            page: 1,
            limit: '$vuetify.dataFooter.itemsPerPageText',
            totalMerchantTransactions: 0,
            totalPayconnectTransctions: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 10
            },
            details: {
                title: '',
                item: {}
            },
            fromDateMenu: false,
            fromDateVal: "",
            toDateMenu: false,
            toDateVal: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMax: "",
            toMin: "",
            offset: 0,
            limit: 0,
            displayMerchantTransactions: [],
            displayPayconnectTransactions: [],
            remainderMerchantTransactions: [],
            remainderPayconnectTransactions: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false,
            selected: [],
	    	selectAll: false,
            totalCount:'',
            tab: null,

        }
    },
    async mounted() {
        this.setDefaultDateRange()
    },
    watch: {
        options: {
            handler() {
                this.queryData(false);
                this.setData();
            },
        },
        listOfMerchantCodes: {
            handler(value) {
                this.merchantCodes = value
            }
        },
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async setData() {
            try {
                const listResponse = await API.getMerchantCode()
                if (!listResponse && listResponse.error) {
                    console.log(`${listResponse.error}`)
                } else {
                    this.listOfMerchantCodes = listResponse.merchantCodes
                }
            } catch (e) {
                console.log(e)
            }
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                var merchantTransactionList = undefined
                var payconnectTransactionList = undefined
                const tabTransaction = this.tab === 0 ? 'MERCHANT' : 'PAYCONNECT'
                
                const parameters = {
                    merchantCode: this.filteredTransaction.merchantCode,
                    payconnectReference: this.filteredTransaction.payconnectReference,
                    merchantReference: this.filteredTransaction.merchantReference,
                    dateFrom: this.fromDateVal, 
                    dateTo: this.toDateVal,
                    pageNo: pageNumber,
                    pageSize: itemsPerPage
                }

                merchantTransactionList = await API.getDeclinedTransaction(parameters)
                if (!merchantTransactionList || merchantTransactionList.error) {
                    console.log(`${merchantTransactionList.error}`)
                } else {
                    var filteredList = merchantTransactionList.merchantTransactions
                    var length = merchantTransactionList.rowCount
                    if (page > 1 && this.remainderMerchantTransactions.length > 0) {
                        filteredList = this.remainderMerchantTransactions.concat(filteredList)
                    }
                    // this.totalMerchantTransactions = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    this.totalMerchantTransactions = length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayMerchantTransactions = filteredList.slice(0, end)
                    this.remainderMerchantTransactions = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalMerchantTransactions / itemsPerPage

                    parameters.offset = this.totalMerchantTransactions
                    parameters.limit = 1
                    
                    const hasMoreResponse = merchantTransactionList
                    if (!hasMoreResponse || hasMoreResponse.error) {
                        console.log(`${hasMoreResponse.error}`)
                    } else {
                        var hasMoreList = hasMoreResponse.merchantTransactions
                        if(hasMoreList.length > 0) {
                        // this.totalMerchantTransactions = Math.floor(this.totalMerchantTransactions/10) * 10 + 1
                        this.numberOfPages = this.numberOfPages + 1
                        }
                    }
                }
               
            } catch (e) {
                console.log(e)
            }
            this.loading = false
        },
        searchMerchantCode: async function (val) {
            this.filteredTransaction.merchantCode = val
            // this.queryData(true)
        },
        searchPayConnectRef(val) {
            this.filteredTransaction.payconnectReference = val
            // this.queryData(true)
        },
        searchMerchantRef(val) {
            this.filteredTransaction.merchantReference = val
            // this.queryData(true)
        },
        getToday() {
            const today = moment().format("yyyy-MM-DD")
            return today
        },
        setDefaultDateRange() {
            this.fromDateVal = this.getToday();
            this.toDateVal = ''
            this.maxDate = moment().add(1, "days").format("yyyy-MM-DD")
            this.fromMax = moment().add(1, "days").format("yyyy-MM-DD")
            this.toMin = moment().format(this.fromDateVal)
        },
        clearToDate() {
            this.toDateVal = ''
            this.toDateMenu = false
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        viewTransaction(transaction) {
            console.log(transaction)
            this.details.title = transaction.sevenPayID
            this.details.merchantId = transaction.merchant
            this.details.status = transaction.paymentStatus
            this.details.item = transaction
            this.isShowDetails = true
        },
        acceptTransactions(transactions) {
            // FOLLOW THIS FORMAT! 
            // sample transactions [{transaction},{transaction},{transaction}]
        },
        declineTransactions(transactions) {

        }
    }
};
</script>
